import React from 'react'

import { Col, Row } from 'react-bootstrap'

const StatSalle = () => {
  return (
    <div>
        <Row>
            <Col></Col>
            <Col>
             <h1>Stat Salle</h1>
            
            </Col>
        </Row>
    </div>
  )
}

export default StatSalle
