import React, {useState, useEffect, useContext, useCallback} from 'react';
import { Form, Container, Row, Col, Table, Modal } from 'react-bootstrap';
import { Button, Stack, TextField, MenuItem, Grid } from '@mui/material';
import Menu from './Menu';
import { useForm } from 'react-hook-form';
import { AuthenticateContext } from '../../config/AuthContext';
import api from '../../config/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa9, faDeleteLeft, faFileExcel, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { sakkal } from '../../config/arabicfontspdf';
import autoTable from 'jspdf-autotable';




function Affecter() {
    const id_departement = localStorage.getItem('departement'); 
    const [filieres,setFilieres] = useState([]);
     const [profs,setProfs] = useState([]);
    const [modules,setModules] = useState([]);
    let  [semestre,setSemestre] = useState([]);
    const [choixSM,setChoixSM] = useState("");
    const [modulesSemestre,setModulesSemestre] = useState([]);
    const { register, reset, handleSubmit} = useForm();
    const [hideSem,setHideSem] = useState(true);
    const [hideMod,setHideMod] = useState(true);
    const [hideGroup,setHideGroup] = useState(true);
    const [hideProf,setHideProf] = useState(true);
    const [hideBtn,setHideBtn] = useState(true);
    const [modulesAffected,setAffectedModules] = useState([]);
    const [authInfo] = useContext(AuthenticateContext);
    const [rechercheParNom, setRechercheParNom] = useState('');
    const [rechercheParSem, setRechercheParSem] = useState('');
    const [rechercheParFil, setRechercheParFil] = useState('');
    const [year,setYear] = useState(null);
    
    //----------------------------------------------
//-----------------Modal-------------------
    const [show, setShow] = useState(false);
    const [del, setDel] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = (delId) => {setDel(delId); setShow(true); }
 //---------------export xlsx-------------------------
  const dataForExport = useCallback(()=>{
  let dataexp = modulesAffected?.filter(mat => {
    return rechercheParNom.toLowerCase() === '' ? mat : mat.nom_fr.toLowerCase().includes(rechercheParNom);
  }).filter(mat => {
    return ("SM"+rechercheParSem.toString().toLowerCase()) === '' ? mat : ("SM"+mat.id_semestre.toString()).toLowerCase().includes(rechercheParSem);
  }).filter(mat => {
    console.log(mat)
    return rechercheParFil === '' ? mat : mat.libelle_filiere.includes(rechercheParFil);
  });
  console.log("----data expo---", dataexp);
  var wb = XLSX.utils.book_new(),
   ws = XLSX.utils.json_to_sheet(dataexp);
  XLSX.utils.book_append_sheet(wb, ws,"profs");
  XLSX.writeFile(wb, "affectations.xlsx");
  
 },[rechercheParSem, rechercheParFil,rechercheParNom])

//----------------export pdf--------------------------------------

const dataForPdf = useCallback(()=>{
  let dataexp = modulesAffected?.filter(mat => {
    return rechercheParNom.toLowerCase() === '' ? mat : mat.nom_fr.toLowerCase().includes(rechercheParNom);
  }).filter(mat => {
    return ("SM"+rechercheParSem.toString().toLowerCase()) === '' ? mat : ("SM"+mat.id_semestre.toString()).toLowerCase().includes(rechercheParSem);
  }).filter(mat => {
    console.log(mat)
    return rechercheParFil === '' ? mat : mat.libelle_filiere.includes(rechercheParFil);
  });
  console.log("----data expo---", dataexp);
  const doc = new jsPDF();
  doc.addFileToVFS('majalla-normal.ttf', sakkal);
  doc.addFont('majalla-normal.ttf', 'majalla', 'normal');
  doc.setFont("majalla");
  let txt = "Affectation des modules - إسناد الوحدات ";
  let xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(txt) * 3); 
  doc.text(txt,xOffset,10);
  //----------add image logo------------
  //doc.addImage(dataimg,'png',xOffset,5,60,30);
  //----------------------------------
  doc.autoTable({
    theme:'grid',
    headStyles: { fillColor: '#00aced', textColor: '#000000' , fontStyle: 'arial' },
    bodyStyles: {textColor: '#333333' , font: 'majalla' ,fontSize: 10},
    columns: [
      { header: 'Nom', dataKey: 'nom_fr' },
      { header: 'Prénom', dataKey: 'prenom_fr' },
      { header: 'Module', dataKey: 'module_name' },
      { header: 'Semestre', dataKey: 'id_semestre' },
      { header: 'Filière', dataKey: 'libelle_filiere' },
    ],
    body: dataexp,
  });
  
  doc.save('table.pdf');
  
 },[rechercheParSem, rechercheParFil,rechercheParNom])
 //------------------
//---------------------get Anne univ ----------------------
const getYearUniv =useCallback(async () =>{
  const url = "/api/anneeuniv";
  api.get(url)
  .then(rep =>{
      console.log(rep.data.annee[0].annee_univ);
      setYear(rep.data.annee[0].annee_univ);
      getAffectedModules(rep.data.annee[0].annee_univ);
  }).catch(err => {
    console.error(err);
  });
},[]);
    
    const getAffectedModules =useCallback(async (annee) =>{
        //-------------affected modules----------------------- 
          const url = "/api/affectedmodules/"+id_departement+"/"+annee+"/"+(annee+1);
          api.get(url)
          .then(rep =>{
              console.log(rep.data.modules);
              setAffectedModules(rep.data.modules);
          }).catch(err => {
            console.error(err);
          });
     },[]);
     //--------------------------------------------------------
     const getFilieresAndPorfOfDepartement = useCallback(async () => {
      const url = "/api/filieresofdepart/"+id_departement;
        api.get(url)
        .then(rep =>{
            setFilieres(rep.data.filieres);
            console.log(rep.data.filieres);
            console.log(id_departement)
        }).catch(err => {
           console.error(err);
        });
        //----------------prof of departement---
        const urlprof = "/api/profsofdepartement/"+id_departement;
        api.get(urlprof)
        .then(rep =>{
            setProfs(rep.data.profs);
            console.log(rep.data.profs);
            console.log(id_departement)
        }).catch(err => {
           console.error(err);
        });
        
    },[]);
    //-----------------------------------------
    
    useEffect(()=>{
      getYearUniv();
      getAffectedModules(year);
      getFilieresAndPorfOfDepartement();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[getAffectedModules,getFilieresAndPorfOfDepartement,getYearUniv]);
    //---------------getModules du filière----------
    const getModules = async (idfil) => {
        const url = "/api/modulesoffiliere/"+idfil;
        api.get(url)
        .then(rep =>{
            setModules(rep.data.modules);
            console.log(rep.data.modules);
            console.log(idfil);

            
            setSemestre([]);
            //-----------------------extraction des semestre uniques--
           let sem = [];
            let count = 0;
            let start = false;
             
            for (let j = 0; j < rep.data.modules.length; j++) {
                for (let k = 0; k < sem.length; k++) {
                    if (rep.data.modules[j].id_semestre === sem[k]) {
                        start = true;
                    }
                }
                count++;
                if (count === 1 && start === false) {
                    sem.push(rep.data.modules[j].id_semestre);
                }
                start = false;
                count = 0;
            }
            setSemestre(sem);

            ////---------------fin extraction des semestre uniques-------------------------
          
            console.log(semestre);
        }).catch(err => {
        console.error(err);
        });
        }
    //-----------------getModules du filière-----------------------
    //---------------------Modules d'un semestre--------
    const modulesDeSmestreEtGroupe = (sem,g) => {
        let arr = [];
        setModulesSemestre(arr);
       console.log("--------le contenu de modules", modules);
       let modaff = [...modulesAffected.map(m=>({"mod_id":m.id_module, "groupe":m.groupe}))];
       console.log("modaff : ", modaff);
    //--------------Construction de module-groupe qui ne sont pas affectés---   
      for (let i = 0; i <modules.length; i++) {
        let obj = {"mod_id":modules[i].id_module, "groupe":g};
        console.log("obj: ", obj);
        let existing = modaff.find(e => (e.mod_id == obj.mod_id)&& (e.groupe == obj.groupe) ) == undefined;
        console.log("existing: ", existing);
        if( modules[i].id_semestre == sem && existing) {
            arr.push(modules[i]); 
        }  
    }
    setModulesSemestre(arr);
    //--------------Fin Construction de module-groupe qui ne sont pas affectés---   
}
 
    //---------------------------------------------------------------
    const sendAffectation = (data) => {
        
        if (data.filiere==='' || data.semestre === ''|| data.groupe === '' || data.module === '' || data.professeur === ''){
          alert('Please select');

        }
        else{
        console.log(data);
        
        let annee = year+"/"+(year+1);
        api.post('/api/affectation',{
            "id_professor":data.professeur,
            "id_module":data.module,
            "annee":annee,
            "groupe":data.groupe,
        },
        {
          headers: {
            'auth-token': authInfo.token
            }
        }
        ).then(rep => {
          console.log(rep.data);
          getAffectedModules(year);
          console.log(modulesAffected);
          setHideSem(true);
          setHideProf(true);
          setHideMod(true);
          setHideGroup(true);
          setHideBtn(true);
          reset();
        }).catch(err => {
          console.log(err);
          console.log(authInfo.token)
        });
        
        }//fin else
        
      }
      //------------------Delete Affectation-------------
      const deleteAffectation = async (id) => {
        api.delete('/api/deleteaffectation/' +id,{
          headers: {
            'auth-token': authInfo.token
            }
        }).then(rep => {
          console.log(rep.data);
          getAffectedModules(year);
          console.log(modulesAffected);
          
        }).catch(err => {
          console.log(err);
        });
      }
    //--------------------------------------
  return (
    <Container className="departement_bg">
        <Menu/>
        <h2 className='titrefil text-center mt-4'>Affectation des modules aux enseignants- Année universitaire {year+"/"+(year+1)}</h2>
       <Row>
        <Col md={4}>
        <Form onSubmit={handleSubmit(sendAffectation)}>
  {/*====================================AU==================================== */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Année Universitaire</Form.Label>
            <Form.Control type="text" disabled  value={year+"/"+(year+1)} {...register('annee_univ')} />
        </Form.Group>
  {/*====================================Filière==================================== */}
        <Form.Group as={Row} className="mb-3" controlId="formBasicEmail">
            <Form.Label column sm="3" className='departement_label'>Filière: </Form.Label>
            <Col sm="9">
            <Form.Select className='departement_form' {...register('filiere')} onChange={(e)=>{getModules(e.target.value); setChoixSM(''); setHideSem(false)}} aria-label="Default select example">
                <option selected value=''>Choisir la filière</option>
                {filieres.map((fil,i) => <option value={fil.id_filiere} key={i}>{fil.libelle_filiere}</option>)}
            </Form.Select>
            </Col>
        </Form.Group>
  {/*====================================Semestre==================================== */}
  <Form.Group as={Row} className="mb-3" controlId="formBasicEmail" hidden={hideSem}>
            <Form.Label column sm="3" className='departement_label'>Semestre</Form.Label>
            <Col sm="9">
              <Form.Select className='departement_form' {...register('semestre')} onChange={(e)=>{setChoixSM(e.target.value); console.log("choix sem hammad:",e.target.value); setHideGroup(false)}}>
                  <option selected value=''>Semestre</option>
                  {semestre.map((s,i) =><option value={s} key={i}>{"SM"+s}</option>)}
              </Form.Select>
            </Col>
          </Form.Group>
  {/*====================================Groupe==================================== */}
  <Form.Group as={Row} className="mb-3" controlId="formBasicEmail" hidden={hideGroup}>
            <Form.Label column sm="3" className='departement_label'>Groupe</Form.Label>
            <Col sm="9">
              <Form.Select className='departement_form' {...register('groupe')} onChange={(e)=>{modulesDeSmestreEtGroupe(choixSM,e.target.value); console.log(e.target.value); setHideMod(false);}}>
              <option selected value=''>Choisir le groupe</option>
                  <option value='GR01'>GR01</option>
                  <option value='GR02'>GR02</option>
                  <option value='GR03'>GR03</option>
                  <option value='GR04'>GR04</option>
              </Form.Select>
            </Col>
          </Form.Group>
  {/*====================================Modules==================================== */}
  <Form.Group as={Row} className="mb-3" controlId="formBasicEmail" hidden={hideMod}>
            <Form.Label column sm="3" className='departement_label'>Modules</Form.Label>
            <Col sm="9">
              <Form.Select className='departement_form' {...register('module')} onChange={(e)=>{setHideProf(false)}}>
                   <option selected value=''>Choisir le module</option>
                  {modulesSemestre.map((mod,i) =><option value={mod.id_module} key={i}>{mod.module_name}</option>)}
              </Form.Select>
            </Col>
          </Form.Group>
  {/*====================================Prof==================================== */}
  <Form.Group as={Row} className="mb-3" controlId="formBasicEmail" hidden={hideProf}>
            <Form.Label column sm="3" className='departement_label'>Professeur</Form.Label>
            <Col sm="9">
              <Form.Select className='departement_form' {...register('professeur')} onChange={(e)=>setHideBtn(false)}>
                   <option selected value=''>Choisir le professeur</option>
                   {profs.map((prof,i) =><option value={prof.id_professor} key={i}>{prof.nom_fr} {prof.prenom_fr}</option>)}
              </Form.Select>
            </Col>
          </Form.Group>
  {/*====================================Button affecter==================================== */}
  <Form.Group className="mb-3"  hidden={hideMod}>
  <Button className='col-12' variant="contained" color="info" type="submit" hidden={hideBtn}>
                    AFFECTER
                  </Button>
          </Form.Group>
  {/*====================================Fin form==================================== */}
    </Form>
        </Col>
        <Col md={8}>
        <h1 className='titrefil text-center mt-4'>Modules Affectés</h1>
        <Col>
        <Button variant="contained" color="success" className='col-3 mb-2' onClick={()=>dataForExport()} ><FontAwesomeIcon size='2x' icon={faFileExcel} /></Button>
        <Button style={{"marginLeft":"10px"}} variant="contained" color="error" className='col-3 mb-2' onClick={()=>dataForPdf()} ><FontAwesomeIcon size='2x' icon={faFilePdf} /></Button>
        </Col>
        
   <Table striped bordered hover className='tab'>
   <thead >
    <tr>
      <th>N°</th>
      <th>Professeur</th>
      <th>Module</th>
      <th>Semestre</th>
      <th>Groupe</th>
      <th>Filière</th>
      <th><FontAwesomeIcon size={fa9} icon={faDeleteLeft} /></th>
    </tr>
  </thead>
      <tbody>
      <tr>
        <td></td>
        <td><input type="text" onChange={(e) => setRechercheParNom(e.target.value)} className="form-control modif"  /></td>
        <td className='bg-gray'></td>
        
        <td>
          <select  className='form-control myselect' onChange={(e) => {setRechercheParSem(e.target.value);}}>
            <option value="" selected>Tout </option>
            <option value="1">SM1</option>
            <option value="2">SM2</option>
            <option value="3">SM3</option>
            <option value="4">SM4</option>
            <option value="5">SM5</option>
            <option value="6">SM6</option>
          </select>
          </td>
          <td className='bg-gray'></td>
          <td>
          <select  className='form-control myselect' onChange={(e) => setRechercheParFil(e.target.value)}>
            <option value="" selected>Tout </option>
            {filieres.map((f, i) =><option value={f.libelle_filiere} key={i}>{f.libelle_filiere}</option>)}
          </select>
          </td>
          <td className='bg-gray'></td>
      </tr>
        {
            modulesAffected?.filter(mat => {
              return rechercheParNom.toLowerCase() === '' ? mat : mat.nom_fr.toLowerCase().includes(rechercheParNom);
            }).filter(mat => {
              return ("SM"+rechercheParSem.toString().toLowerCase()) === '' ? mat : ("SM"+mat.id_semestre.toString()).toLowerCase().includes(rechercheParSem);
            }).filter(mat => {
              console.log(mat)
              return rechercheParFil === '' ? mat : mat.libelle_filiere.includes(rechercheParFil);
            })
            .map((module,i)=><tr key={i}>
                <td>{i+1}</td>
                <td>{module.nom_fr} {module.prenom_fr}</td>
                <td>{module.module_name}</td>
                <td>{"SM"+module.id_semestre}</td>
                <td>{module.groupe}</td>
                <td>{module.libelle_filiere}</td>
                <td><Button onClick={()=>handleShow(module.id)}  variant='text' ><FontAwesomeIcon icon={faTrash} color='red' /></Button></td>
                
              </tr>)
        }
        
       
      </tbody>
    </Table>
    {/***********Modal*********************/}
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer cette affectation?</Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
          <Button variant='contained' color='warning' onClick={handleClose}>
            Annuler
          </Button>
          <Button variant='contained' color='info' onClick={()=>{deleteAffectation(del);handleClose();}}>
            Oui
          </Button>
          </Stack>
        </Modal.Footer>
      </Modal>

    {/*************************************/}
        </Col>
       </Row>
    </Container>
  )
}

export default Affecter