import React, {useState, useEffect, useContext, useCallback} from 'react';
import { AuthenticateContext } from '../../config/AuthContext';
import api from '../../config/axios';
import { useForm } from 'react-hook-form';
import { Form, Container, Row, Col, Table, Modal } from 'react-bootstrap';
import { Button, Stack } from '@mui/material';
import { fa9, faDeleteLeft, faFileExcel, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menu from './Menu';
function Users() {
    const [authInfo] = useContext(AuthenticateContext);
    const [users, setUsers] = useState([]);
    const [departements, setDepartements] = useState([]);
    //-----------------Modal-------------------
    const [show, setShow] = useState(false);
    const [del, setDel] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = (delId) => {setDel(delId); setShow(true); }
    const { register, reset, handleSubmit} = useForm();
    //============================AllUsers=======================
    const getAllUsers =useCallback(async () =>{
          const url = "/api/users";
          api.get(url,{
            headers: {
              'auth-token': authInfo.token
              }
          })
          .then(rep =>{
              console.log(rep.data.users);
              setUsers(rep.data.users);
          }).catch(err => {
            console.error(err);
          });
     },[]);
     //============================AllDep=======================
    const getAllDepart =useCallback(async () =>{
        const url = "/api/alldepart";
        api.get(url)
        .then(rep =>{
            console.log(rep.data.departements);
            setDepartements(rep.data.departements);
        }).catch(err => {
          console.error(err);
        });
   },[]);
     //==========================Delete users========================
     const deleteUser = async (id) => {
        api.delete('/api/deleteuser/' +id,{
          headers: {
            'auth-token': authInfo.token
            }
        }).then(rep => {
          console.log(rep.data);
          getAllUsers();
          
        }).catch(err => {
          console.log(err);
        });
      }
      //=========================addUser==========
      const addUser = (data) => {
        console.log(data);
        api.post('/api/user',{
            "nom":data.nom,
            "prenom":data.prenom,
            "email":data.email,
            "password":data.password,
            "role":data.role,
            "id_departement":data.id_departement
        },
        {
          headers: {
            'auth-token': authInfo.token
            }
        }
        ).then(rep =>{
           getAllUsers();
        }).catch(err => {
          console.log(err);
          console.log(authInfo.token)
        });
      }
    //===============================================================
    useEffect(()=>{
       getAllUsers();
       getAllDepart();
      },[getAllUsers,getAllDepart]);
    //===============================================================
  return (
  <Container>
    <Menu/>
    <Row>
        <Col>
         <h3 className='titrefil text-center mt-4'>Ajouter un utilisateur</h3>
        </Col>
    </Row>
    <Row>
    <Col>
        <Form onSubmit={handleSubmit(addUser)}>
<Row>
  {/*====================================Nom==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label>Nom</Form.Label>
            <Form.Control type="text" {...register('nom')} />
        </Form.Group>
{/*====================================prénom==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label>Prénom</Form.Label>
            <Form.Control type="text" {...register('prenom')} />
        </Form.Group>
{/*====================================Login==================================== */}
</Row>
<Row>
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label>Login</Form.Label>
            <Form.Control type="text" {...register('email')} />
        </Form.Group>
{/*====================================Login==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label>Mot de passe</Form.Label>
            <Form.Control type="password" {...register('password')} />
        </Form.Group>

</Row>
<Row>
    {/*====================================Login==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label>Role</Form.Label>
            <Form.Select  {...register('role')} >
                  <option value='chef-département'>Chef-département</option>
                  <option value='admin'>Admin</option>
                  <option value='user'>user</option>
                  
              </Form.Select>
        </Form.Group>
     {/*====================================Departement==================================== */}
     <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label>Département</Form.Label>
            <Form.Select  {...register('id_departement')} >
                  <option value=''></option>
                  {departements.map(dep =><option key={dep.id_departement} value={dep.id_departement}>{dep.libelle_departement}</option>)}
                  
              </Form.Select>
        </Form.Group>
</Row>
{/*====================================Button Ajouter==================================== */}
<Form.Group className="mb-3">
  <Button className='col-12' variant="contained" color="info" type="submit">
                    Ajouter
                  </Button>
          </Form.Group>
        </Form>
        </Col>
    </Row>
    <Table striped bordered hover className='tab'>
   <thead >
    <tr>
      <th>N°</th>
      <th>Nom</th>
      <th>Prénom</th>
      <th>Login</th>
      <th>Role</th>
      <th><FontAwesomeIcon size={fa9} icon={faDeleteLeft} /></th>
    </tr>
  </thead>
      <tbody>
        {users.map(user =><tr key={user.id}><td>{user.id}</td><td>{user.nom}</td><td>{user.prenom}</td><td>{user.email}</td><td>{user.role}</td><td><Button onClick={()=>handleShow(user.id)} variant='text' ><FontAwesomeIcon icon={faTrash} color='red' /></Button></td></tr>)}

      </tbody>
</Table>
{/***********Modal*********************/}
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer?</Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
          <Button variant='contained' color='warning' onClick={handleClose}>
            Annuler
          </Button>
          <Button variant='contained' color='info' onClick={()=>{deleteUser(del);handleClose();}}>
            Oui
          </Button>
          </Stack>
        </Modal.Footer>
      </Modal>

    {/*************************************/}
  </Container>
  )
}

export default Users