import React, { useState, useCallback, useContext, useEffect } from 'react'
import api from '../../config/axios';
import Menu from './Menu';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { DataGrid} from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { AuthenticateContext } from '../../config/AuthContext';

function ProfAbsence() {
    const [profs,setProfs] = useState([]);
    const [authInfo] = useContext(AuthenticateContext);
    const [year,setYear] = useState(null);
    const [absences, setAbsences] =useState([]);
    //-------------All profs--------------------
    const getPorfs = useCallback(async () => {
          
        const urlprof = "/api/profs"; 
        api.get(urlprof,{
            headers: {
              'auth-token': authInfo.token
              }
          })
        .then(rep =>{
            setProfs(rep.data.profs);
            console.log(rep.data.profs)
           
        }).catch(err => {
           console.error(err);
        });
        
    },[]);

    const getYearUniv =useCallback(async () =>{
        const url = "/api/anneeuniv";
        api.get(url)
        .then(rep =>{
            //console.log(rep.data.annee[0].annee_univ);
            setYear(rep.data.annee[0].annee_univ);
            //getAffectedModules(rep.data.annee[0].annee_univ, id_departement);
        }).catch(err => {
          console.error(err);
        });
      },[]);

    //---------------------------------------------
  useEffect(()=>{
      getPorfs();
      getYearUniv();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[getPorfs,getYearUniv]);
    //------------------------------------------------------
    const [nomProf, setNomProf] = React.useState("");
    const [idProf, setIdProf] = React.useState(0);
    const [selectedNomProf, setSelectedNomProf] = useState('');
    const handleChangeProf = (event) => {
    setIdProf(event.target.value);
    setSelectedNomProf(event.target.value);
    console.log(event.target.value)
    const xx = profs.filter(p=>p.id_professor==event.target.value);
    setNomProf(xx[0]['nom_fr']+" "+xx[0]['prenom_fr'])   
  };
  //-------------------------------getAbsenceProf-------
  const getAbsencesOfProf = async ()=>{
    let y1 = year;
    let y2 = y1+1;
    api.get('/api/profabsence/'+idProf+'/'+y1+'/'+y2)
    .then(rep=>{
        console.log(rep.data.absences);
        setAbsences(rep.data.absences)
        
    }).catch(err => {
        console.error(err);
      });
}
//
const columns = [
  {
    field: 'nom',
    headerName: 'Nom',
    width: 150,
    editable: false,
    headerClassName: 'text_bold',
  },
  {
      field: 'prenom',
      headerName: 'Prénom',
      width: 150,
      editable: false,
      headerClassName: 'text_bold',
    }, 
    {
      field: 'date_absence',
      headerName: "Date d'absence",
      width: 150,
      editable: false,
      headerClassName: 'text_bold',
    }, 
    {
      field: 'date_saisie',
      headerName: "Date de saisie",
      width: 150,
      editable: false,
      headerClassName: 'text_bold',
    },     
  {
    field: 'libelle_filiere',
    headerName: 'Filière',
    width: 150,
    editable: false,
    headerClassName: 'text_bold',
  },
  {
    field: 'module_name',
    headerName: 'Module',
    width: 250,
    editable: false,
    headerClassName: 'text_bold',
  },
 
    {
      field: 'salle',
      headerName: 'Salle',
      width: 150,
      headerClassName: 'text_bold',
      editable: false,
    },
    {
      field: 'remarque',
      headerName: 'Remarque',
      width: 150,
      editable: false,
      headerClassName: 'text_bold',
    }
 
];
  //--------------------------------------------------


  return (
    <Box sx={{ flexGrow: 1 }}>
        <Menu/>
    <Grid container spacing={2} marginTop={4}>
      <Grid item xs={12}>
        <h3 className='text-center text-danger'>Absences d'enseignant</h3>
      </Grid>
    <Grid item xs={12}>
       <FormControl className='col-12'  sx={{ m: 1, minWidth: 120 }}>
       <InputLabel id="demo-simple-select-helper-label">Enseignant(e)</InputLabel>
       <Select
         labelId="demo-simple-select-helper-label"
         id="demo-simple-select-helper"
         label="Enseignants"
         value={selectedNomProf}
         onChange={e=>handleChangeProf(e)}
       >
         <MenuItem value="">
           <em>None</em>
         </MenuItem>
         {profs.map((p,i) =><MenuItem value={p.id_professor} key={i}>{p.nom_fr} {p.prenom_fr}</MenuItem>)}
       </Select>
      
       </FormControl>
    </Grid>
    <Grid item md={3}></Grid>
    <Grid item md={6} xs={12}>
                <Button onClick={()=>{getAbsencesOfProf()}} className='col-12'  variant="contained" color="error" size='large'>
                    Absence de {nomProf}
                </Button>
    </Grid>
    <Grid item md={3}></Grid>

    </Grid>
    {/*******Tableau liste d'absence********** */}
    <Grid container spacing={2} marginTop={4}>
        <Grid item xs={12}> 
        <DataGrid
        rows={absences}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        
      />
        </Grid>
        </Grid>
         {/*******fin Tableau liste d'absence********** */}
    </Box>
  )
}

export default ProfAbsence