import React, { useCallback, useEffect } from 'react'
import api from '../../config/axios';
import Menu from './Menu';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { DataGrid} from '@mui/x-data-grid';
import { signal } from '@preact/signals'
import { useSignals } from "@preact/signals-react/runtime";
import { InputLabel, MenuItem, Select } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel} from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';

const vacatairesSignal = signal([]);
const listYearSignal = signal([]);
const yearSignal = signal(0);
function ListeVacataires() {

  //---------------------get Anne univ ----------------------
const getYearUniv =useCallback(async () =>{
  console.log("im getYearUniv im executing.....");
  const url = "/api/anneeuniv";
  api.get(url)
  .then(rep =>{
      yearSignal.value = rep.data.annee[0].annee_univ;
      const currentYear = new Date().getFullYear();
      listYearSignal.value = Array.from({ length: currentYear - 2024 + 1 }, (_, i) => 2024 + i);
      console.log(listYearSignal.value);
            
  }).catch(err => {
    console.error(err);
  });
},[]);
//---------
useEffect(() =>{
  getYearUniv();
  //getAllVacataireByYear(yearSignal.value);
},[]);
//------------------------------------------------------------------------------
  const getAllVacataireByYear = async (year)=>{

    const url = "/api/vacataires/"+year+"/"+(year+1);
  api.get(url)
  .then(rep =>{
      console.log(rep.data.vacataires);
       vacatairesSignal.value = rep.data.vacataires.map((row, index) => ({
        id: index + 1, // ou utiliser une bibliothèque comme uuid pour un id unique
        ...row,
      }));
      
  }).catch(err => {
    console.error(err);
  }); 
  }
  //-------------------------------------------------------------------
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },   
    {
      field: 'nom_fr',
      headerName: 'Nom',
      width: 150,
      editable: false,
      headerClassName: 'text_bold',
    },
    {
      field: 'prenom_fr',
      headerName: 'Prénom',
      width: 250,
      editable: false,
      headerClassName: 'text_bold',
    },
    {
      field: 'module_name',
      headerName: 'Module',
      width: 150,
      editable: false,
      headerClassName: 'text_bold',
    },
    {
        field: 'libelle_filiere',
        headerName: 'Filière',
        width: 150,
        editable: false,
        headerClassName: 'text_bold',
      },
      {
        field: 'libelle_departement',
        headerName: 'Département',
        width: 150,
        editable: false,
        headerClassName: 'text_bold',
      },
      {
        field: 'id_sessions',
        headerName: 'Session',
        width: 150,
        headerClassName: 'text_bold',
        editable: false,
      },
      {
        field: 'id_semestre',
        headerName: 'Sem',
        width: 150,
        headerClassName: 'text_bold',
        editable: false,
      },
      {
        field: 'jour',
        headerName: 'Jour',
        width: 150,
        headerClassName: 'text_bold',
        editable: false,
      },
      {
        field: 'creneau',
        headerName: 'Créneau',
        width: 150,
        headerClassName: 'text_bold',
        editable: false,
      },
      {
        field: 'salle',
        headerName: 'Salle',
        width: 150,
        headerClassName: 'text_bold',
        editable: false,
      }
   
  ];
//------------------------Export Excel-------------------------
const exportVacExcel = ()=>{
 const dataexp = vacatairesSignal.value;
  // Création du classeur Excel
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(dataexp);
  XLSX.utils.book_append_sheet(wb, ws, "vacataires");

  // Enregistrement du fichier Excel
  const filename = "listeDesVacataires"+(yearSignal.value)+"-"+(yearSignal.value+1)+".xlsx";
  XLSX.writeFile(wb, filename);
}
//------------------------------------------------------------------------------
useSignals();  
return (
    <Box sx={{ flexGrow: 1 }}>
        <Menu/>

        <Grid container spacing={2} marginTop={4}>
            <Grid item xs={12}>
                 <div className='display-3 text-primary text-center'>Liste de vacataires {yearSignal.value}/{yearSignal.value+1}</div>
            </Grid>
            <Grid item xs={12} md={6}>
            <InputLabel id="demo-simple-select-label">Année universitaire</InputLabel>
        <Select
          className='col-12'
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value="{yearSignal.value}"
          
          onChange={(e)=>yearSignal.value=e.target.value}
        >
          {listYearSignal.value.map((y,i)=><MenuItem key={i} value={y}>{y}/{y+1}</MenuItem>)}
          
        </Select>
            
            </Grid>
            <Grid item xs={12} md={6} marginTop={2}> 
                <Button onClick={()=>getAllVacataireByYear(yearSignal.value)} className='col-12'  variant="contained" color="success">
                        Afficher
                </Button>
            </Grid>
    </Grid>

    <Grid container spacing={2} marginTop={2}>

    <Grid item xs={12} md={4} marginTop={2}> 
                <Button onClick={()=>exportVacExcel()} className='col-12'  variant="contained" color="success">
                <FontAwesomeIcon size='2x' icon={faFileExcel} />
                </Button>
            </Grid>

    </Grid>
{/**------------------------------------- */}
{/****************************************************** */}
<Grid marginTop={4}>
            <Grid item xs={12}>
                {vacatairesSignal.value.length>0?<h3>{vacatairesSignal.value.length} Modules</h3>:<h3> </h3>}
            </Grid>
</Grid>

{/*******Tableau liste d'absence********** */}
<Grid container spacing={2} marginTop={4}>
        <Grid item xs={12}> 
        <DataGrid
        rows={vacatairesSignal.value}
        columns={columns}
        
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        
      />
        </Grid>
        </Grid>
         {/*******fin Tableau liste d'absence********** */}

    </Box>
  )
}

export default ListeVacataires