import React, { useRef } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const Telecharger = () => {
    const contentRef = useRef();
    const exportPDF = () => {
        const input = contentRef.current;
        html2canvas(input, { 
            scale: 2,
            useCORS: true 
        }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210; 
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            while (heightLeft > pageHeight) {
                heightLeft -= pageHeight;
                position -= pageHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            }
            pdf.save('download.pdf');
        });
    };
    return (
        <>
            <div ref={contentRef} style={{ width: '210mm', minHeight: '297mm', padding: '20mm', boxSizing: 'border-box' }}>
            <table
              align="center"
              border="0"
              cellPadding="0"
              cellSpacing="0"
              style={{ fontSize: '12px', width: '100%' }}
            >
  <tbody>
    <tr>
      <td valign="top">
        <table width="100%" cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <td valign="bottom" width="50%" height="50">
                <div align="left">
                  <img
                    src="https://www.inv24.com/components/Users/pics/58cd70c222196/thumbs/0.jpg"
                    alt="Logo"
                    crossOrigin="anonymous"
                  />
                </div>
                <br />
              </td>
              <td width="50%">&nbsp;</td>
            </tr>
          </tbody>
        </table>
        Destinataire: <br />
        <br />
        <table width="100%" cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <td valign="top" width="35%" style={{ fontSize: '12px' }}>
                <strong>[Nom de l'entreprise du client]</strong>
                <br />
                [Adresse de l'entreprise du client]
                <br />
                SIRET: [SIRET du client]
                <br />
                No de TVA: [Numéro de TVA du client]
                <br />
              </td>
              <td valign="top" width="35%"></td>
              <td valign="top" width="30%" style={{ fontSize: '12px' }}>
                Date de facturation: 14/07/2021
                <br />
                Échéance: 28/07/2021
                <br />
              </td>
            </tr>
          </tbody>
        </table>
        <table width="100%" height="100" cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <td>
                <div align="center" style={{ fontSize: '14px', fontWeight: 'bold' }}>
                  Facture № 553
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          width="100%"
          cellSpacing="0"
          cellPadding="2"
          border="1"
          borderColor="#CCCCCC"
        >
          <tbody>
            <tr>
              <td
                width="35%"
                borderColor="#ccc"
                bgcolor="#f2f2f2"
                style={{ fontSize: '12px' }}
              >
                <strong>الاسم</strong>
              </td>
              <td
                borderColor="#ccc"
                bgcolor="#f2f2f2"
                style={{ fontSize: '12px' }}
              >
                <strong>Quantité</strong>
              </td>
              <td
                borderColor="#ccc"
                bgcolor="#f2f2f2"
                style={{ fontSize: '12px' }}
              >
                <strong>Prix</strong>
              </td>
              <td
                borderColor="#ccc"
                bgcolor="#f2f2f2"
                style={{ fontSize: '12px' }}
              >
                <strong>Total</strong>
              </td>
            </tr>
            <tr style={{ display: 'none' }}>
              <td colSpan="*"></td>
            </tr>
            <tr>
              <td valign="top" style={{ fontSize: '12px' }}>
                mon produit
              </td>
              <td valign="top" style={{ fontSize: '12px' }}>
                3
              </td>
              <td valign="top" style={{ fontSize: '12px' }}>
                60,00
              </td>
              <td valign="top" style={{ fontSize: '12px' }}>
                180,00
              </td>
            </tr>
            <tr>
              <td valign="top" style={{ fontSize: '12px' }}>
                mon service
              </td>
              <td valign="top" style={{ fontSize: '12px' }}>
                1
              </td>
              <td valign="top" style={{ fontSize: '12px' }}>
                120,00
              </td>
              <td valign="top" style={{ fontSize: '12px' }}>
                120,00
              </td>
            </tr>
            {/* Empty rows */}
            {[...Array(13)].map((_, idx) => (
              <tr key={idx}>
                <td valign="top" style={{ fontSize: '12px' }}>
                  &nbsp;
                </td>
                <td valign="top" style={{ fontSize: '12px' }}>
                  &nbsp;
                </td>
                <td valign="top" style={{ fontSize: '12px' }}>
                  &nbsp;
                </td>
                <td valign="top" style={{ fontSize: '12px' }}>
                  &nbsp;
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <table width="100%" cellSpacing="0" cellPadding="2" border="0">
          <tbody>
            <tr>
              <td style={{ fontSize: '12px', width: '50%' }}>
                <strong></strong>
              </td>
              <td>
                <table width="100%" cellSpacing="0" cellPadding="2" border="0">
                  <tbody>
                    <tr>
                      <td align="right" style={{ fontSize: '12px' }}>
                        Total
                      </td>
                      <td align="right" style={{ fontSize: '12px' }}>
                        300,00 €
                      </td>
                    </tr>
                    <tr>
                      <td align="right" style={{ fontSize: '12px' }}>
                        <b>Total TTC</b>
                      </td>
                      <td align="right" style={{ fontSize: '12px' }}>
                        <b>300,00 €</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table width="100%" height="50">
          <tbody>
            <tr>
              <td style={{ fontSize: '12px', textAlign: 'justify' }}>
                TVA non applicable, article 293B du code général des impôts
              </td>
            </tr>
          </tbody>
        </table>
        <table width="100%" cellSpacing="0" cellPadding="2">
          <tbody>
            <tr>
              <td
                width="33%"
                style={{
                  borderTop: 'double medium #CCCCCC',
                  fontSize: '12px',
                }}
                valign="top"
              >
                <b>[Nom de l'entreprise]</b>
                <br />
                SIRET: [SIRET]
                <br />
              </td>
              <td
                width="33%"
                style={{
                  borderTop: 'double medium #CCCCCC',
                  fontSize: '12px',
                  textAlign: 'center',
                }}
                valign="top"
              >
                [Adresse]
                <br />
                Tél: [Téléphone]
                <br />
              </td>
              <td
                width="34%"
                style={{
                  borderTop: 'double medium #CCCCCC',
                  fontSize: '12px',
                  textAlign: 'right',
                }}
                valign="top"
              >
                [Nom de la banque]
                <br /> [Compte bancaire (IBAN)]
                <br />
                SWIFT/BIC: [SWIFT/BIC]
                <br />
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>

            </div>
            <button className='btn btn-devis' onClick={exportPDF}>Télécharger en PDF</button>
        </>
    );
};

export default Telecharger;

  