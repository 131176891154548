import React from 'react'
import { Page,Image, Text, View, Document, StyleSheet,Font } from '@react-pdf/renderer';
import aljazeera from '../../fonts/Al-Jazeera-Arabic-Regular.ttf';
const logo = require('../../images/logo40.png'); // with require


function PdfPAge(props) {
    // Enregistre la police arabe
Font.register({
    family: 'ArabicFont',
    src: aljazeera,
  });
    // -----------------Create styles
const styles = StyleSheet.create({
    page: {
        padding: 20,
      },
      header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 20, // Ajoute un espace après l'en-tête
      },
    image: {
        width: 141,
        height: 70,
        marginVertical: 15,
      
    },
    body: {
        fontSize: 12,
        textAlign: 'center',
      },
      //---------table----------
      table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
        marginTop: 20,
      },
      tableRow: {
        flexDirection: 'row',
      },
      tableCol: {
        width: '10%', // 10 colonnes = 10% chacune
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
        padding: 5,
      },
      tableCell: {
        textAlign: 'center',
        fontSize: 10,
      },
      //------------------
  });
  // -----------------fin Create styles
      return (
      
<Document>
      <Page size="A4" orientation='landscape' style={styles.page}>
      <View style={styles.header}>
            <Image
                style={styles.image}
                src={logo}
            />
      </View>
      <View style={styles.body}>   
            <View style={{ fontFamily: 'ArabicFont', direction: 'rtl' }}>
                <Text>{props.nom}</Text>
            </View>
            <View style={styles.section}>
                <Text>Section 2-------------2</Text>
            </View>
                <View style={styles.section}>
                <Text>Section #3</Text>
                <Text style={{ marginRight:20, fontFamily: 'ArabicFont', direction: 'rtl' }}>الكرام بتحويل الكتابة</Text>
                <Text style={{ fontFamily: 'ArabicFont', direction: 'rtl' }}>الكرام بتحويل الكتابة</Text>
                <Text style={{ fontFamily: 'ArabicFont', direction: 'rtl' }}>الكرام بتحويل الكتابة</Text>
                </View>
                {/* Tableau */}
      <View style={styles.table}>
        {/* Ligne 1 */}
        <View style={styles.tableRow}>
          {[...Array(10)].map((_, i) => (
            <View style={styles.tableCol} key={i}>
              <Text style={styles.tableCell}>Cell {i + 1}</Text>
            </View>
          ))}
        </View>

        {/* Ligne 2 */}
        <View style={styles.tableRow}>
          {[...Array(10)].map((_, i) => (
            <View style={styles.tableCol} key={i}>
              <Text style={styles.tableCell}>Cell {i + 11}</Text>
            </View>
          ))}
        </View>
      </View>
        </View>
      </Page>
</Document>
          
       
      );
}

export default PdfPAge