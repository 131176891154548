import React, { useState, useCallback, useContext, useEffect } from 'react'
import api from '../../config/axios';
import Menu from './Menu';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DataGrid} from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { AuthenticateContext } from '../../config/AuthContext';

function ListeAbsences() {
    const [absences, setAbsences] =useState([]);
    
    const [authInfo] = useContext(AuthenticateContext);
    //------------------------date aujourd'hui-----------
    const currentDate = new Date();
    const aujourdhui = currentDate.toISOString().substring(0, 10);
    //-----------------------------------------------------------
    const [date,setDate] = useState(aujourdhui);
    //---------------------------------
    const handleChangeDate = (e)=>{
        setDate(e.target.value)
        console.log(e.target.value);
    }
    //-----------------------------------------------
    const getAbsences = async ()=>{
        api.get('/api/absence/'+date)
        .then(rep=>{
            console.log(rep.data.absences);
            setAbsences(rep.data.absences)
            
        }).catch(err => {
            console.error(err);
          });
    }
    
    //*******************fin all profs */
//--------------------------------------------------------
//-----------------jour------------------


//--------------------------------------------------
    const columns = [
        
        {
          field: 'libelle_filiere',
          headerName: 'Filière',
          width: 150,
          editable: false,
          headerClassName: 'text_bold',
        },
        {
          field: 'module_name',
          headerName: 'Module',
          width: 250,
          editable: false,
          headerClassName: 'text_bold',
        },
        {
          field: 'nom',
          headerName: 'Nom',
          width: 150,
          editable: false,
          headerClassName: 'text_bold',
        },
        {
            field: 'prenom',
            headerName: 'Prénom',
            width: 150,
            editable: false,
            headerClassName: 'text_bold',
          },
          {
            field: 'salle',
            headerName: 'Salle',
            width: 150,
            headerClassName: 'text_bold',
            editable: false,
          },
          {
            field: 'remarque',
            headerName: 'Remarque',
            width: 150,
            editable: false,
            headerClassName: 'text_bold',
          }
       
      ];
      
      

    ///--------------------------------------

  return (
    <Box sx={{ flexGrow: 1 }}>
        <Menu/>
        <Grid container spacing={2} marginTop={4}>
            <Grid item xs={12}>
                 <div className='display-3 text-primary text-center'>Liste d'absence</div>
            </Grid>
            <Grid item xs={12} md={6}>
            <TextField
                className='col-12'
                id="date"
                label="Date d'absence"
                type="date"
                defaultValue={date}
                InputLabelProps={{
                shrink: true,
        }}
        onChange={(e)=>handleChangeDate(e)}
      />
            </Grid>
            <Grid item xs={12} md={6} marginTop={2}> 
                <Button onClick={getAbsences} className='col-12'  variant="contained" color="success">
                        Afficher
                </Button>
            </Grid>
    </Grid>
{/**------------------------------------- */}


{/****************************************************** */}
<Grid marginTop={4}>
            <Grid item xs={12}>
                {absences.length>0?<h3>{absences.length} absences</h3>:<h3>Acune absence en {date}</h3>}
            </Grid>
</Grid>
    

    
        {/*******Tableau liste d'absence********** */}
        <Grid container spacing={2} marginTop={4}>
        <Grid item xs={12}> 
        <DataGrid
        rows={absences}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        
      />
        </Grid>
        </Grid>
         {/*******fin Tableau liste d'absence********** */}
    </Box>
  )
}

export default ListeAbsences