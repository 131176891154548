import React, {useState, useEffect, useContext, useCallback} from 'react';
import { AuthenticateContext } from '../../config/AuthContext';
import api from '../../config/axios';
import { Form, Container, Row, Button, Col, Table, Modal } from 'react-bootstrap';
import Menu from './Menu';
function AddUnivYear() {
    const [authInfo] = useContext(AuthenticateContext);
    const [y,setY] = useState(null);
    const [year,setYear] = useState(null);
    //---------------------get Anne univ ----------------------
const getYearUniv =useCallback(async () =>{
    const url = "/api/anneeuniv";
    api.get(url)
    .then(rep =>{
        console.log(rep.data.annee[0].annee_univ);
        setYear(rep.data.annee[0].annee_univ)
    }).catch(err => {
      console.error(err);
    });
  },[]);
  //-------------------------------------------
    const updateYear = async () => {
        let url = "/api/updateyear/"+y;
        api.get(url,{
            headers: {
              'auth-token': authInfo.token
              }
          })
        .then(response =>{
            console.log(response.data);
            getYearUniv();
        }).catch(err => {
            console.error(err);
          });
    }
    //=====================================================
    useEffect(()=>{
        getYearUniv();
       },[getYearUniv]);
     //===============================================================
  return (
    <Container>
        <Menu/>
        <Row>
            <Col>
            <h2 className='mt-3 text-center'>L'année universitaire actuelle pour l'application est: </h2>
            <h1 className='mt-3 text-center text-primary'>{year}</h1>
            </Col>
        </Row>
        <Row>
        <Form.Group className="mb-3 col-6 offset-3" controlId="formBasicEmail">
            <Form.Label>Année universitaire (ex pour 2025/2026 saisir 2025)</Form.Label>
            <Form.Control className='year' type="number" onChange={e => setY(e.target.value)} placeholder='Année univ'/>
        </Form.Group>
        <Button onClick={()=>updateYear()} className='success col-6 offset-3'>Update L'année universitaire</Button>
        </Row>
        
    </Container>
  )
}

export default AddUnivYear