import React from 'react'
import api from '../../config/axios'
import { useState, useEffect } from 'react';
import { Container, Table } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import Menu from './Menu';

function ModulesListe() {
    const {idfil} = useParams();
    const {filiere} = useParams();
    const [modules,setModules] = useState([]);
    useEffect(()=>{
        //-----------getModules------------
        const getModules = async (req, res) => {
        const url = "/api/modulesoffiliere/"+idfil;
        api.get(url)
        .then(rep =>{
            setModules(rep.data.modules);
            console.log(rep.data.modules);
            console.log(idfil);
        }).catch(err => {
        console.error(err);
        });
        };
        //--------------------------------
        getModules();
    },[idfil])
  return (
 <Container>
    <Menu/>
 
   <h1 className='titrefil text-center mt-4'>{ filiere}</h1>
   <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Semestre</th>
          <th>Module</th>
          <th>VH</th>
          <th>Parcours</th>
          <th>Code Module</th>
        </tr>
      </thead>
      <tbody>
        {
            modules.map((module,i)=><tr>
                <td>{i+1}</td>
                <td>{"SM"+module.id_semestre}</td>
                <td>{module.module_name}</td>
                <td>{module.vh}</td>
                <td>{module.parcours}</td>
                <td>{module.cod_mod}</td>
                
              </tr>)
        }
        
       
      </tbody>
    </Table>
    </Container>
  )
}

export default ModulesListe