import React, { useEffect } from 'react'
import {Container, Row} from 'react-bootstrap';

import api from '../../config/axios'
import { useState } from 'react';
import Cardfiliere from './Cardfiliere';
import Menu from './Menu';
function CpDepart() {
   
    
    const id_departement = localStorage.getItem('departement'); 
    const [filieres,setFilieres] = useState([]);

    useEffect(()=>{
        //---------------fileres-----------
        const getFilieresOf = async () => {
          const url = "/api/filieresofdepart/"+id_departement;
            api.get(url)
            .then(rep =>{
                setFilieres(rep.data.filieres);
                console.log(rep.data.filieres);
                console.log(id_departement)
            }).catch(err => {
               console.error(err);
            });
            
        };
        //---------------------------------
        getFilieresOf();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[1])
  return (
    <Container>
      <Menu/>  
      <Row>
         {filieres?.map((fil,i)=><Cardfiliere type={fil.type_ar} titre={fil.libelle_filiere} idfil={fil.id_filiere} key={i} />)}
      </Row>

      
    </Container>
  )
}

export default CpDepart