import React from 'react'
import {Container, Nav, Navbar, NavDropdown, Row, Col} from 'react-bootstrap';
import { useContext } from 'react';
import { Link} from 'react-router-dom'
import { AuthenticateContext } from '../../config/AuthContext';
function Menu() {
    const [authInfo,setAuthInfo] = useContext(AuthenticateContext);
    const nom = localStorage.getItem('nom');
    const role = localStorage.getItem('role');
  return (
   <>
    <Row>
      <Col style={{'color':'#ad5389'}} className="display-1 text-uppercase text-center">
           FLSHM-Pédagogie
      </Col>
    </Row>
   {/********************************************** */}
   <Navbar expand="lg" className="navbar navbar-dark menu">
      <Container>
  
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link}  to="/cpadmin">ACCUEIL</Nav.Link>
            <Nav.Link as={Link}  to="/users" >Users</Nav.Link>
            <Nav.Link as={Link}  to="/profs" >Profs</Nav.Link>
            <Nav.Link as={Link}  to="/addyear" >Année Universitaire</Nav.Link>
            
            <NavDropdown title="EMPLOI DU TEMPS" id="basic-nav-dropdown">
            
            <NavDropdown.Item  as={Link}  to="/affectermodule2">
              Affecter Module 
            </NavDropdown.Item>
            <NavDropdown.Divider />
              <NavDropdown.Item  as={Link}  to="/addplanning">
               Ajouter planning
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/showplanning">EMPLOI DU TEMPS: Prof</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/showfiliereplanning">EMPLOI DU TEMPS: Filière</NavDropdown.Item>
             <NavDropdown.Item as={Link} to="/sallesdispo">Salles disponibles</NavDropdown.Item>
             <NavDropdown.Item as={Link} to="/vacataires">Listes des vacataires</NavDropdown.Item>
             <NavDropdown.Divider />
             <NavDropdown.Item as={Link} to="/updatetitremodule">Modifier Titre de module</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="ABSENCES" id="basic-nav-dropdown">
              <NavDropdown.Item  as={Link}  to="/addabsences">
               Ajouter Absence
              </NavDropdown.Item>
              <NavDropdown.Item  as={Link}  to="/addrattrapage">
               Ajouter Rattrapage
              </NavDropdown.Item>
              
              <NavDropdown.Divider />
              <NavDropdown.Item  as={Link}  to="/listeabsences">
               Liste d'absence
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item  as={Link}  to="/profabsence">
                Absence par Enseignant
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link}  to="/statistique" >Statistiques</Nav.Link>
            <Nav.Link onClick={()=>setAuthInfo(()=>({token:'',isAuthenticated:false}))} href="#">Logout</Nav.Link>
          </Nav>
          
        </Navbar.Collapse>
        
        <Navbar.Brand href="#" style={{'fontSize':'9pt'}}>
        {nom}:{role}
          </Navbar.Brand>
         
      </Container>
    </Navbar>
      {/*********************************************** */}
   </>
  )
}

export default Menu