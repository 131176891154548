import React from 'react'
import {Container, Nav, Navbar, NavDropdown, Row} from 'react-bootstrap';
import { useContext } from 'react';
import { Link} from 'react-router-dom'
import { AuthenticateContext } from '../../config/AuthContext';
function Menu() {
    const [authInfo,setAuthInfo] = useContext(AuthenticateContext);
    const nom = localStorage.getItem('nom');
    const role = localStorage.getItem('role');
  return (
   <>
   {/********************************************** */}
   <Navbar expand="lg" className="navbar navbar-dark menu">
      <Container>
  
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link}  to="/cpdepartement">ACCUEIL</Nav.Link>
            <Nav.Link as={Link}  to="/affecter">AFFECTER</Nav.Link>
            <Nav.Link href="#home">EMPLOI DU TEMPS</Nav.Link>
            
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">TEST</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link onClick={()=>setAuthInfo(()=>({token:'',isAuthenticated:false}))} href="#">Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Brand href="#" style={{'fontSize':'9pt'}}>
        {nom}:{role}
          </Navbar.Brand>
      </Container>
    </Navbar>
      {/*********************************************** */}
   </>
  )
}

export default Menu