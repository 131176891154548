import React, {useState, useEffect, useContext, useCallback} from 'react';
import { Form, Container, Row, Col, Table, Modal } from 'react-bootstrap';
import { Button, Stack, TableBody} from '@mui/material';
import Menu from './Menu';
import { useForm } from 'react-hook-form';
import { AuthenticateContext } from '../../config/AuthContext';
import api from '../../config/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa9, faDeleteLeft, faFileExcel, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { sakkal } from '../../config/arabicfontspdf';
//import autoTable from 'jspdf-autotable';

function AffecterModuleAuProf2() {
    
    const [filieres,setFilieres] = useState([]);
     const [profs,setProfs] = useState([]);
     const [departements, setDepartements] = useState([]);
    const [modules,setModules] = useState([]);
    let  [semestre,setSemestre] = useState([]);
    const [choixSM,setChoixSM] = useState("");
    const [choixGroup,setchoixGroup] = useState("");
    const [modulesSemestre,setModulesSemestre] = useState([]);
    const { register, reset, handleSubmit} = useForm();
    const [hideSem,setHideSem] = useState(true);
    const [hideMod,setHideMod] = useState(true);
    const [hideGroup,setHideGroup] = useState(true);
    const [hideProf,setHideProf] = useState(true);
    const [hideBtn,setHideBtn] = useState(true);
    const [modulesAffected,setAffectedModules] = useState([]);
    const [authInfo] = useContext(AuthenticateContext);
    const [rechercheParNom, setRechercheParNom] = useState('');
    const [rechercheParSem, setRechercheParSem] = useState('');
    const [rechercheParFil, setRechercheParFil] = useState('');
    const [id_departement,setIdDepartement] = useState(null);
    const [year,setYear] = useState(null);
    const [selectedProfForModule,setSelectedProfForModule] = useState([]);
    let [nbrModuleAffected,setNbrModuleAffected] = useState(0);
    
    //----------------------------------------------
//-----------------Modal-------------------
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [del, setDel] = useState(0);
    const handleClose = () => setShow(false);
    const handleClose2 = () => setShow2(false);
    const handleShow = (delId) => {setDel(delId); setShow(true); }
 //---------------export xlsx-------------------------
  
const dataForExport = useCallback(() => {
  // Filtrer les modules en fonction des critères
  const dataexp = modulesAffected
    ?.filter(mat => {
      // Vérification du filtre par nom (rechercheParNom)
      return rechercheParNom.toLowerCase() === '' 
        ? true 
        : mat.nom_fr.toLowerCase().includes(rechercheParNom.toLowerCase());
    })
    .filter(mat => {
      // Vérification du filtre par semestre (rechercheParSem)
      return rechercheParSem === '' 
        ? true 
        : `SM${mat.id_semestre}`.toLowerCase().includes(rechercheParSem.toLowerCase());
    })
    .filter(mat => {
      // Vérification du filtre par filière (rechercheParFil)
      return rechercheParFil === '' 
        ? true 
        : mat.libelle_filiere.toLowerCase().includes(rechercheParFil.toLowerCase());
    });

  console.log("----data expo---", dataexp);

  // Création du classeur Excel
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(dataexp);
  XLSX.utils.book_append_sheet(wb, ws, "profs");

  // Enregistrement du fichier Excel
  XLSX.writeFile(wb, "affectations.xlsx");
}, [modulesAffected, rechercheParSem, rechercheParFil, rechercheParNom]);

//----------------export pdf--------------------------------------

const dataForPdf = useCallback(()=>{
  let dataexp = modulesAffected
  ?.filter(mat => {
    // Vérification du filtre par nom (rechercheParNom)
    return rechercheParNom.toLowerCase() === '' 
      ? true 
      : mat.nom_fr.toLowerCase().includes(rechercheParNom.toLowerCase());
  })
  .filter(mat => {
    // Vérification du filtre par semestre (rechercheParSem)
    return rechercheParSem === '' 
      ? true 
      : `SM${mat.id_semestre}`.toLowerCase().includes(rechercheParSem.toLowerCase());
  })
  .filter(mat => {
    // Vérification du filtre par filière (rechercheParFil)
    return rechercheParFil === '' 
      ? true 
      : mat.libelle_filiere.toLowerCase().includes(rechercheParFil.toLowerCase());
  });
  //console.log("----data expo---", dataexp);
  const doc = new jsPDF();
  doc.addFileToVFS('majalla-normal.ttf', sakkal);
  doc.addFont('majalla-normal.ttf', 'majalla', 'normal');
  doc.setFont("majalla");
  let txt = "Affectation des modules - إسناد الوحدات ";
  let xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(txt) * 3); 
  doc.text(txt,xOffset,10);
  //----------add image logo------------
  //doc.addImage(dataimg,'png',xOffset,5,60,30);
  //----------------------------------
  doc.autoTable({
    theme:'grid',
    headStyles: { fillColor: '#00aced', textColor: '#000000' , fontStyle: 'arial' },
    bodyStyles: {textColor: '#333333' , font: 'majalla' ,fontSize: 10},
    columns: [
      { header: 'Nom', dataKey: 'nom_fr' },
      { header: 'Prénom', dataKey: 'prenom_fr' },
      { header: 'Module', dataKey: 'module_name' },
      { header: 'Semestre', dataKey: 'id_semestre' },
      { header: 'Filière', dataKey: 'libelle_filiere' },
    ],
    body: dataexp,
  });
  
  doc.save('table.pdf');
  
 },[rechercheParSem, rechercheParFil,rechercheParNom])
 //------------------
 //============================AllDep=======================
 const getAllDepart =useCallback(async () =>{
    const url = "/api/alldepart";
    api.get(url)
    .then(rep =>{
        //console.log(rep.data.departements);
        setDepartements(rep.data.departements);
    }).catch(err => {
      console.error(err);
    });
},[]);
//---------------------get Anne univ ----------------------
const getYearUniv =useCallback(async () =>{
  const url = "/api/anneeuniv";
  api.get(url)
  .then(rep =>{
      //console.log(rep.data.annee[0].annee_univ);
      setYear(rep.data.annee[0].annee_univ);
      getAffectedModules(rep.data.annee[0].annee_univ, id_departement);
  }).catch(err => {
    console.error(err);
  });
},[id_departement]);
    
    const getAffectedModules =useCallback(async (annee,depID) =>{
     //-------------affected modules----------------------- 
          const url = "/api/affectedmodules/"+depID+"/"+annee+"/"+(annee+1);
          api.get(url)
          .then(rep =>{
             // console.log(rep.data.modules);
              setAffectedModules(rep.data.modules);
          }).catch(err => {
            console.error(err);
          });
      //-------------------------------------------------- 
     },[]);
     //--------------------------------------------------------
     const getFilieresAndPorfOfDepartement = useCallback(async () => {
      const url = "/api/filieresofdepart/"+id_departement;
        api.get(url)
        .then(rep =>{
            setFilieres(rep.data.filieres);
            //console.log(rep.data.filieres);
            //console.log(id_departement)
        }).catch(err => {
           console.error(err);
        });
        //----------------prof of departement---
        const urlprof = "/api/profs"; //ofdepartement/"+id_departement;
        api.get(urlprof,{
            headers: {
              'auth-token': authInfo.token
              }
          })
        .then(rep =>{
            setProfs(rep.data.profs);
           // console.log(rep.data.profs);
            //console.log(id_departement)
        }).catch(err => {
           console.error(err);
        });
        
    },[id_departement]);
    //-----------------------------------------
    useEffect(()=>{
        getAllDepart();
       },[getAllDepart]);
    //-----------------------------------------
    useEffect(()=>{
        getAffectedModules(year,id_departement);
       },[getAffectedModules]);
   //---------------------------------------------
    useEffect(()=>{
      getYearUniv();
     
      getFilieresAndPorfOfDepartement();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[getFilieresAndPorfOfDepartement,getYearUniv]);
    //---------------getModules du filière----------
    const getModules = async (idfil) => {
        const url = "/api/modulesoffiliere/"+idfil;
        api.get(url)
        .then(rep =>{
            setModules(rep.data.modules);
           // console.log(rep.data.modules);
            //console.log(idfil);

            
            setSemestre([]);
            //-----------------------extraction des semestre uniques--
           let sem = [];
            let count = 0;
            let start = false;
             
            for (let j = 0; j < rep.data.modules.length; j++) {
                for (let k = 0; k < sem.length; k++) {
                    if (rep.data.modules[j].id_semestre === sem[k]) {
                        start = true;
                    }
                }
                count++;
                if (count === 1 && start === false) {
                    sem.push(rep.data.modules[j].id_semestre);
                }
                start = false;
                count = 0;
            }
            setSemestre(sem);

            ////---------------fin extraction des semestre uniques-------------------------
          
            //console.log("---semestres---",semestre);
        }).catch(err => {
        console.error(err);
        });
        }
    //-----------------getModules du filière-----------------------
    //---------------------Modules d'un semestre--------
    
/************************************************************** */
const showmoduleOfDepAndFilAndGroupe = (sem,g) => {
  setHideMod(false);
    let arr = [];
    setModulesSemestre(arr);
  // console.log("--------le contenu de modules", modules);
   let modaff = [...modulesAffected.map(m=>({"mod_id":m.id_module, "groupe":m.groupe}))];
   //console.log("modaff : ", modaff);
//--------------Construction de module-groupe qui ne sont pas affectés---   
  for (let i = 0; i <modules.length; i++) {
    let obj = {"mod_id":modules[i].id_module, "groupe":g};
    //console.log("obj: ", obj);
    let existing = modaff.find(e => (e.mod_id == obj.mod_id)&& (e.groupe == obj.groupe) ) == undefined;
    //console.log("existing: ", existing);
    if( modules[i].id_semestre == sem && existing) {
        arr.push(modules[i]); 
    }  
}

console.log(arr);
console.log("Semestre: ",choixSM)
console.log("Groupe: ",choixGroup);
setModulesSemestre(arr);
//--------------Fin Construction de module-groupe qui ne sont pas affectés--- 
  } 

/*************************************************************  */
const handleInputChange = (intervenant,module, value) => {
  //console.log(module)
  let update = false;
  let selectedPM = selectedProfForModule;
  for(let i = 0; i <selectedPM.length; i++) {
    //console.log(module.module_name)
    //console.log(selectedPM[i].module)
    if(selectedPM[i].module===module.id_module && selectedPM[i].intervenant===intervenant){
      //update prof 
      selectedPM[i].profId = value;
      update = true;
      setSelectedProfForModule(selectedPM);
    }
  }
  if(!update){
    let obj = {"intervenant":intervenant,"module": module.id_module, "profId": value}
    const updatedValues = [...selectedProfForModule,obj];
    setSelectedProfForModule(updatedValues);
  }  
  
};

 
    //---------------------------------------------------------------
    const sendAffectation = () => {
      
      console.log(selectedProfForModule)
      
        let annee = year+"/"+(year+1);
// ajout de la liste des affectations 
      let koko = 0;
       for(const profMod of selectedProfForModule){
        try{
          koko++;//compteur des modules
          console.log("-----------------------------------");
          console.log("id_professor",profMod.profId);
          console.log("id_module",profMod.module,)

          api.post('/api/affectation',{
            "id_professor":profMod.profId,
            "id_module":profMod.module,
            "annee":annee,
            "groupe":choixGroup,
        },
        {
          headers: {
            'auth-token': authInfo.token
            }
        }
        ).then(rep => {
          
          
          //console.log(modulesAffected);
          setHideSem(true);
          setHideProf(true);
          setHideMod(true);
          setHideGroup(true);
          setHideBtn(true);
          reset();
        }).catch(err => {
          //console.log(err);
          //console.log(authInfo.token)
        });

        }catch(e){
          console.log(e);
        }

       }//fin for
       //alert(koko + "  modules sont affectés!");
       setNbrModuleAffected(koko);
       setShow2(true);
       getAffectedModules(year,id_departement);
       setSelectedProfForModule([]);
       setIdDepartement(prev => null); // pour initialiser la page
      }
      //------------------Delete Affectation-------------
      const deleteAffectation = async (id) => {
        
        api.delete('/api/deleteaffectation/' +id+'/'+year+'/'+(year+1),{
          headers: {
            'auth-token': authInfo.token
            }
        }).then(rep => {
          //console.log(rep.data);
          getAffectedModules(year,id_departement);
          //console.log(modulesAffected);
          
        }).catch(err => {
          console.log(err);
        });
      }
    //--------------------------------------
  return (
    <Container className="departement_bg">
        <Menu/>
        <h2 className='titrefil text-center mt-4'>Affectation des modules aux enseignants- Année universitaire {year+"/"+(year+1)}</h2>
       <Row>
        <Col md={12}>
        <Form>
  {/*====================================AU==================================== */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Année Universitaire</Form.Label>
            <Form.Control type="text" disabled  value={year+"/"+(year+1)} {...register('annee_univ')} />
        </Form.Group>
{/*====================================Département==================================== */}
<Form.Group as={Row} className="mb-3" controlId="formBasicEmail">
            
            <Col sm="3">
            <Form.Select className='departement_form' onChange={(e)=>{setIdDepartement(prev => e.target.value);setFilieres(prev=>[]); console.log(id_departement)}} aria-label="Default select example">
                <option selected value="">Choisir la département</option>
                {departements.map((dep,i) => <option value={dep.id_departement} key={i}>{dep.libelle_departement}</option>)}
            </Form.Select>
            </Col>
            {/*====================================Filière==================================== */}
       
            <Col sm="3">
            <Form.Select className='departement_form' {...register('filiere')} onChange={(e)=>{getModules(e.target.value);}} aria-label="Default select example">
                <option selected value="0">Choisir la filière</option>
                {filieres.map((fil,i) => <option value={fil.id_filiere} key={i}>{fil.libelle_filiere}</option>)}
            </Form.Select>
            </Col>
            {/*====================================Semestre==================================== */}
  
            <Col sm="3">
              <Form.Select className='departement_form' {...register('semestre')} onChange={(e)=>{setChoixSM(e.target.value); console.log("choix sem hammad:",e.target.value);}}>
                  <option selected value="">Semestre</option>
                  {semestre.map((s,i) =><option value={s} key={i}>{"SM"+s}</option>)}
              </Form.Select>
            </Col>
            <Col sm="3">
              <Form.Select className='departement_form' {...register('groupe')} onChange={(e)=>{setchoixGroup(e.target.value) ;console.log(e.target.value);}}>
              <option selected value="">Choisir le groupe</option>
                  <option value='GR01'>GR01</option>
                  <option value='GR02'>GR02</option>
                  <option value='GR03'>GR03</option>
                  <option value='GR04'>GR04</option>
              </Form.Select>
            </Col>
            {/*===========================button resultat======================================== */}
            <Col sm={{ span: 6, offset: 3 }} className='mt-3'>
            <Button className='col-12' variant='contained' color='info' onClick={()=>{showmoduleOfDepAndFilAndGroupe(choixSM,choixGroup);}}>
            Afficher les modules
          </Button>
            </Col>

        </Form.Group>
     
  {/*====================================Groupe==================================== */}
  
            
         
  {/*====================================Modules==================================== */}
  <Form.Group as={Row} className="mb-3" controlId="formBasicEmail" hidden={hideMod}>
            <Col sm="12">
                <Table>
                    <TableBody>
                    {modulesSemestre.map((mod,i) =><tr key={i}>
                      <th>{mod.module_name}</th>
                      <td>
                          <Form.Select className='departement_form'  onChange={(e)=>{handleInputChange("p1",mod,e.target.value);setHideBtn(false)}}>
                            <option selected value=''>Choisir le professeur</option>
                            {profs.map((prof,idx) =><option value={prof.id_professor} key={idx}>{prof.nom_fr} {prof.prenom_fr}</option>)}
                          </Form.Select>
                      </td>
                      <td>
                      <Form.Select className='departement_form' onChange={(e)=>{handleInputChange("p2",mod,e.target.value);setHideBtn(false)}}>
                            <option selected value=''>Choisir le professeur</option>
                            {profs.map((prof,idx2) =><option value={prof.id_professor} key={idx2}>{prof.nom_fr} {prof.prenom_fr}</option>)}
                          </Form.Select>
                      </td>
                      </tr>)}
                    </TableBody>
                </Table>
             
            </Col>
          </Form.Group>


 
  {/*====================================Prof==================================== */}
 
  {/*====================================Button affecter==================================== */}
  <Form.Group className="mb-3"  hidden={hideMod}>
    <Col sm={{ span: 6, offset: 3 }}>
   
                  <Button className='col-12' variant="contained" color="warning" onClick={sendAffectation} hidden={hideBtn}>
                    AFFECTER LES MODULES
                  </Button>
    </Col>
          </Form.Group>
  {/*====================================Fin form==================================== */}
    </Form>
        </Col>
        <Col md={12}>
        <h1 className='titrefil text-center mt-4'>Modules Affectés</h1>
        <Col>
        <Button variant="contained" color="success" className='col-3 mb-2' onClick={()=>dataForExport()} ><FontAwesomeIcon size='2x' icon={faFileExcel} /></Button>
        <Button style={{"marginLeft":"10px"}} variant="contained" color="error" className='col-3 mb-2' onClick={()=>dataForPdf()} ><FontAwesomeIcon size='2x' icon={faFilePdf} /></Button>
        </Col>
        
   <Table striped bordered hover className='tab'>
   <thead >
    <tr>
      <th>N°</th>
      <th>Professeur</th>
      <th>Module</th>
      <th>Semestre</th>
      <th>Groupe</th>
      <th>Filière</th>
      <th><FontAwesomeIcon size={fa9} icon={faDeleteLeft} /></th>
    </tr>
  </thead>
      <tbody>
      <tr>
        <td></td>
        <td><input type="text" onChange={(e) => setRechercheParNom(e.target.value)} className="form-control modif"  /></td>
        <td className='bg-gray'></td>
        
        <td>
          <select  className='form-control myselect' onChange={(e) => {setRechercheParSem(e.target.value);}}>
            <option value="" selected>Tout </option>
            <option value="1">SM1</option>
            <option value="2">SM2</option>
            <option value="3">SM3</option>
            <option value="4">SM4</option>
            <option value="5">SM5</option>
            <option value="6">SM6</option>
          </select>
          </td>
          <td className='bg-gray'></td>
          <td>
          <select  className='form-control myselect' onChange={(e) => setRechercheParFil(e.target.value)}>
            <option value="" selected>Tout </option>
            {filieres.map((f, i) =><option value={f.libelle_filiere} key={i}>{f.libelle_filiere}</option>)}
          </select>
          </td>
          <td className='bg-gray'></td>
      </tr>
        {
            modulesAffected?.filter(mat => {
              return rechercheParNom.toLowerCase() === '' ? mat : mat.nom_fr.toLowerCase().includes(rechercheParNom);
            }).filter(mat => {
              return ("SM"+rechercheParSem.toString().toLowerCase()) === '' ? mat : ("SM"+mat.id_semestre.toString()).toLowerCase().includes(rechercheParSem);
            }).filter(mat => {
              //console.log(mat)
              return rechercheParFil === '' ? mat : mat.libelle_filiere.includes(rechercheParFil);
            })
            .map((module,i)=><tr key={i}>
                <td>{i+1}</td>
                <td>{module.nom_fr} {module.prenom_fr}</td>
                <td>{module.module_name}</td>
                <td>{"SM"+module.id_semestre}</td>
                <td>{module.groupe}</td>
                <td>{module.libelle_filiere}</td>
                <td><Button onClick={()=>handleShow(module.id)}  variant='text' ><FontAwesomeIcon icon={faTrash} color='red' /></Button></td>
                
              </tr>)
        }
        
       
      </tbody>
    </Table>
    {/***********Modal*********************/}
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer cette affectation et le planning associé?</Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
          <Button variant='contained' color='warning' onClick={handleClose}>
            Annuler
          </Button>
          <Button variant='contained' color='info' onClick={()=>{deleteAffectation(del);handleClose();}}>
            Oui
          </Button>
          </Stack>
        </Modal.Footer>
      </Modal>

    {/*************************************/}

    {/***********Modal message Affectation*********************/}
    <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Affectation des modules aux profs</Modal.Title>
        </Modal.Header>
        <Modal.Body>Affectation pour {nbrModuleAffected} Professeur(s)</Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
         
          <Button variant='contained' color='info' onClick={()=>{handleClose2();}}>
            OK
          </Button>
          </Stack>
        </Modal.Footer>
      </Modal>

    {/*************************************/}
        </Col>
       </Row>
    </Container>
  )
}

export default AffecterModuleAuProf2
/*function AffecterModuleAuProf() {
  return (
    <div>AffecterModuleAuProf</div>
  )
}

export default AffecterModuleAuProf*/